<template>
  <div class="task_rate">
    <div class="comment" @click="dialogVisible = true">点评</div>
    <div class="title">
      <span @click="$router.push({ path: '/tasklist' })">作业管理 /</span>
      <span @click="$router.go(-1)">作业详情 /</span>
      作业点评
    </div>
    <div class="name">{{ name }}同学</div>
    <div class="level">班级：{{ classname }}</div>
    <ul class="task_list">
      <li v-for="item in task" :key="item.id">
        <div class="left">
          <p>{{ interpreter("type", item.type) }}</p>
          <el-progress
            type="circle"
            :percentage="Number(item.progress)"
            :stroke-width="20"
            text-color="#2BC571"
            color="#2BC571">
          </el-progress>
        </div>
        <div class="right">
          <div class="stor">
            <p>排名</p>
            <!-- <img src="../../assets/img/53.png" alt="" /> -->

            <p class="p2">{{ item.situation.sort }}</p>
          </div>
          <div class="task_info">
            <div class="info_box">
              <p class="p1">时长（分秒）</p>
              <p class="p2">{{ item.situation.time }}</p>
            </div>
            <div class="info_box">
              <p class="p1">组数（组）</p>
              <p class="p2">{{ item.group_count }}</p>
            </div>
            <div class="info_box">
              <p class="p1">消耗（千卡）</p>
              <p class="p2">{{ item.situation.kcal }}</p>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <el-dialog title="点评" :visible.sync="dialogVisible" width="35%">
      <el-rate v-model="score"></el-rate>
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入内容"
        v-model="textarea">
      </el-input>
      <div class="kj_rate">
        <p class="p2">快捷点评语</p>
        <p @click="quick('比上次有了很大进步，继续努力！')">
          比上次有了很大进步，继续努力！
        </p>
        <p @click="quick('最近表现得非常棒！')">最近表现得非常棒！</p>
        <p @click="quick('要克服困难，保持运动习惯！')">
          要克服困难，保持运动习惯！
        </p>
        <p @click="quick('努力运动才能有个好身体，认真完成体育作业')">
          努力运动才能有个好身体，认真完成体育作业
        </p>
        <p @click="quick('梦想的起航需要好身体，要认真对待体育作业')">
          梦想的起航需要好身体，要认真对待体育作业
        </p>
      </div>
      <div class="btn_box">
        <div class="close_btn" @click="dialogVisible = false">取消</div>
        <div class="comment_btn" @click="sub_comment">提交点评</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      student_id: "",
      homework_id: "",
      name: "",
      classname: "",
      task: null,
      dialogVisible: false,
      score: 0,
      textarea: "请输入点评内容",
      // 对照词典
      dictionaries: {
        train: [
          {
            AI: "智能训练模式",
            CONVENTION: "常规训练模式",
            INTEREST: "趣味训练模式",
          },
        ],
        type: [
          {
            JUMP: "跳绳",
            TRAMPOLINE: "蹦床",
            GYMNASTICS: "跳操",
            LONG_JUMP: "立定跳远",
            RUN_STEP: "跑步",
            BASKETBALL: "篮球",
            SIT_UP: "仰卧起坐",
            JUMPING: "开合跳",
            KICKER: "踢键子",
            SQUAT: "深蹲",
            HIGH_KNEE: "高抬腿",
            KICK_BUTTOCKS: "原地臀踢",
          },
        ],
        mode: [{ COUNT: "计数", TIME: "计时", FREE: "自由" }],
      },
    };
  },
  computed: {
    // 对照翻译字典
    interpreter() {
      return function (name, val) {
        //
        for (let t in this.dictionaries) {
          if (t == name) {
            for (let i in this.dictionaries[name][0]) {
              if (i == val) {
                return this.dictionaries[name][0][i];
              }
            }
          }
        }
      };
    },
  },
  created() {
    this.student_id = this.getQueryValue("student_id");
    this.homework_id = this.getQueryValue("homework_id");
    this.name = this.getQueryValue("name");
    this.classname = this.getQueryValue("classname");
    this.get_task();
  },
  methods: {
    async get_task() {
      const res = await this.axios.get(
        `/homework/${this.homework_id}/student/${this.student_id}`
      );
      if (res.code === 1) {
        this.task = res.data.work;
      }
    },
    quick(text) {
      this.textarea = text;
    },
    async sub_comment() {
      const res = await this.axios.post(
        `/homework/comment/${this.homework_id}/student/${this.student_id}`,
        {
          row: {
            comment: this.textarea,
            score: this.score,
          },
        }
      );
      if (res.code === 1) {
        this.dialogVisible = false;
        this.textarea = "";
        this.score = 0;
        this.$message.success("提交成功");
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.task_rate {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  .comment {
    width: 100px;
    height: 40px;
    background: #516cf7;
    border-radius: 30px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    position: absolute;
    right: 20px;
    top: 40px;
    cursor: pointer;
  }
  .title {
    font-size: 20px;
    font-weight: normal;
    color: #5fa7ff;
    span {
      color: #999999;
      cursor: pointer;
    }
  }
  .name {
    font-size: 25px;
    color: #666666;
    margin-top: 20px;
  }
  .level {
    font-size: 20px;
    color: #999999;
    margin-top: 20px;
  }
  .task_list {
    margin-top: 20px;
    height: 90%;
    overflow-y: auto;
    li {
      display: flex;
      width: 100%;
      padding-top: 20px;
      padding-left: 48px;
      padding-bottom: 35px;
      background: #f2f2f2;
      border-radius: 10px;
      margin-bottom: 20px;
      .left {
        margin-right: 100px;
        p {
          font-size: 25px;
          margin-bottom: 20px;
        }
      }
    }
    .right {
      .stor {
        width: 70px;
        p {
          font-size: 20px;
          text-align: center;
        }
        img {
          width: 70px;
          margin-top: 10px;
        }
      }
      .task_info {
        display: flex;
        margin-top: 20px;
        .info_box {
          margin-right: 20px;
          .p1 {
            font-size: 20px;
            color: #999999;
          }
          .p2 {
            font-size: 30px;
            color: #666666;
          }
        }
      }
    }
  }
  .el-rate {
    text-align: center;
    .el-rate__icon {
      font-size: 26px;
    }
  }
  .el-textarea {
    margin-top: 40px;
  }
  .kj_rate {
    text-align: center;
    margin-top: 20px;
    color: #999999;
    line-height: 25px;
    cursor: pointer;
    .p2 {
      color: #666666;
    }
  }
  .btn_box {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .close_btn {
      width: 100px;
      height: 30px;
      background: #ffffff;
      border-radius: 25px;
      border: 1px solid #999999;
      text-align: center;
      line-height: 30px;
      margin-right: 20px;
      cursor: pointer;
    }
    .comment_btn {
      width: 100px;
      height: 30px;
      border-radius: 25px;
      background: #516cf7;
      text-align: center;
      line-height: 30px;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>
